import './App.css';
import { Suspense, lazy, useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import Context from './Context';
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import ScrollToTop from "./Components/ScrollToTop";
import axios from 'axios';
import Cookies from './Components/Cookies';
import TagManager from 'react-gtm-module';
import SEOHelper from './Components/SEOHelper';
import { setDefaultMetas } from "./Utils/SEOHelper";

const Home = lazy(() => import('./Pages/Home'));
const Sobre = lazy(() => import('./Pages/Sobre'));
const Texto = lazy(() => import('./Pages/Texto'));
const Blog = lazy(() => import('./Pages/Blog'));
const Servico = lazy(() => import('./Pages/Servico'));
const Contato = lazy(() => import('./Pages/Contato'));
const Obrigado = lazy(() => import('./Pages/Obrigado'));
const Post = lazy(() => import('./Pages/Post'));

function App() {
  // localStorage.setItem("apiUrl", "https://intercont.dev.fw2propaganda.com.br/sistema/api/");
  // localStorage.setItem("apiUrl", "http://localhost:8080/api/");
  localStorage.setItem("apiUrl", "/sistema/api/");
  const [resumo, setResumo] = useState({});
  const [modal, setModal] = useState(false);
  const [isRep, setIsRep] = useState(false);
  const [activePage, setActivePage] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [cart, setCart] = useState([]);
  const value = { resumo, setResumo, modal, setModal, activePage, setActivePage, menuOpen, setMenuOpen, cart, setCart, isRep, setIsRep };

  useEffect(() => {
    axios.get(localStorage.getItem("apiUrl") + "configuracoes").then(response => {
      if (response.data.success.seo && response.data.success.seo[0]) {
        setDefaultMetas(response.data.success.seo[0])
      }
      setResumo(response.data.success);
      if (response.data.success.configuracoes.tag_manager) {
        if (response.data.success.configuracoes.tag_manager.length > 5) {
          const tagManagerArgs = {
            gtmId: response.data.success.configuracoes.tag_manager
          }
          let checkCookies = setInterval(() => {
            let accept = localStorage.getItem("acceptCookiesDesnecessarios");
            if (accept) {
              TagManager.initialize(tagManagerArgs)
              clearInterval(checkCookies);
            }
          }, 3000);
        }
      }
    });

    let carrinho = JSON.parse(localStorage.getItem("carrinho"));
    if (!carrinho) {
      carrinho = []
    }
    setCart(carrinho);

    let id = localStorage.getItem("id");
    let codigo = localStorage.getItem("codigo");
    if (id && codigo) {
      axios.get(localStorage.getItem("apiUrl") + "check?id=" + id + "&codigo=" + codigo).then(response => {
        if (response.data.status !== 200) {
          localStorage.removeItem("id");
          localStorage.removeItem("codigo");
        } else {
          if (response.data.success.is_rep === 1) {
            if (response.data.success.is_rep === 1) {
              setIsRep(true);
            } else {
              setIsRep(false);
            }
          }
        }
      });
    }

  }, []);

  return (
    <div className="App">
      <HelmetProvider>
        <Context.Provider value={value}>
          <div id="modal" className={modal ? "active" : ""}>
            {modal && <div className="content">
              <h3>{modal.titulo}</h3>
              <p dangerouslySetInnerHTML={{ __html: modal.texto }}></p>
              <button type="button" onClick={() => { if (modal.onExit) { modal.onExit() } setModal(false) }}>{modal.botao}</button>
            </div>}
          </div>
          <h1 id="mainTitle" className='hidden'>Batrol Móveis</h1>
          {resumo.textos &&
            <Router basename="/">
              <Header />
              <ScrollToTop setMenuOpen={setMenuOpen} menuOpen={menuOpen} />
              {window.defaultMetas && <SEOHelper />}
              <Suspense fallback={<div className="suspense"></div>}>
                <Switch>
                  <Route exact path="/">
                    <Home />
                  </Route>
                  <Route exact path="/empresa">
                    <Sobre />
                  </Route>
                  <Route exact path="/blog">
                    <Blog />
                  </Route>
                  <Route exact path="/blog/:slug">
                    <Post />
                  </Route>
                  <Route exact path="/servico/:slug">
                    <Servico />
                  </Route>
                  <Route exact path="/fale-conosco">
                    <Contato />
                  </Route>
                  <Route exact path="/trabalhe-:trabalhe">
                    <Contato />
                  </Route>
                  <Route exact path="/obrigado/:slug">
                    <Obrigado />
                  </Route>
                  {resumo.textos &&
                    <Route exact path="/termos-de-uso">
                      <Texto titulo={"Termos de uso"} texto={resumo.textos.termos_de_uso} />
                    </Route>
                  }
                  {resumo.textos &&
                    <Route exact path="/politica-de-privacidade">
                      <Texto titulo={"Política de privacidade"} texto={resumo.textos.politica_de_privacidade} />
                    </Route>
                  }
                </Switch>
              </Suspense>
              <Cookies />
              <Footer />
            </Router>
          }
        </Context.Provider>
      </HelmetProvider>
    </div>
  );
}

export default App;
