import { useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import Context from "../../Context";
import styles from "./Header.module.css";

const Header = () => {
    const { menuOpen, setMenuOpen, resumo } = useContext(Context);

    return (
        <div className={styles.header}>
            <div className="container">
                <div className={styles.flex}>
                    <div className="logo">
                        <Link to="/" title="Home"><img src={`${localStorage.getItem('apiUrl')}imagens/medium/logo.svg`} alt="intercont" /></Link>
                    </div>
                    <div className={styles.toggleMenu}>
                        <img onClick={() => setMenuOpen(!menuOpen)} src={`${localStorage.getItem('apiUrl')}imagens/large/${menuOpen ? "close" : "menu"}.svg`} alt="menu" />
                    </div>
                    <div className={`${styles.menu} ${menuOpen ? styles.menuOpen : ""}`}>
                        <Link to="/" title="Home">Home</Link>
                        <div className={styles.submenu}>
                            <p>Serviços</p>
                            <div className={styles.submenu__items}>
                                {resumo.servicos.map(servico => <Link key={`header-servicos-${servico.slug}`} to={`/servico/${servico.slug}`} title={servico.nome}>{servico.nome}</Link>)}
                            </div>
                        </div>
                        <Link to="/empresa" title="Sobre nós">Sobre nós</Link>
                        {resumo.posts && resumo.posts[0] && <Link to="/blog" title="Blog">Blog</Link>}
                        <Link to="/fale-conosco" title="Fale conosco">Contato</Link>
                        <div className={styles.social}>
                            {resumo.configuracoes.facebook.length > 5 && <a href={resumo.configuracoes.facebook} title="Facebook" target={"_blank"} rel="noopener noreferrer"><img src={`${localStorage.getItem('apiUrl')}imagens/medium/facebook.svg`} alt="rede social" /></a>}
                            {resumo.configuracoes.instagram.length > 5 && <a href={resumo.configuracoes.instagram} title="Instagram" target={"_blank"} rel="noopener noreferrer"><img src={`${localStorage.getItem('apiUrl')}imagens/medium/instagram.svg`} alt="rede social" /></a>}
                            {resumo.configuracoes.linkedin.length > 5 && <a href={resumo.configuracoes.linkedin} title="linkedin" target={"_blank"} rel="noopener noreferrer"><img src={`${localStorage.getItem('apiUrl')}imagens/medium/linkedin.svg`} alt="rede social" /></a>}
                            {resumo.configuracoes.youtube.length > 5 && <a href={resumo.configuracoes.youtube} title="youtube" target={"_blank"} rel="noopener noreferrer"><img src={`${localStorage.getItem('apiUrl')}imagens/medium/youtube.svg`} alt="rede social" /></a>}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
};

export default withRouter(Header);



