import { Link } from "react-router-dom";
import { useContext } from "react";
import Context from "../../Context";
import styles from "./Footer.module.css";

const Footer = () => {
    const { resumo } = useContext(Context);
    return (
        <div className={styles.footer}>
            <div className={`container ${styles.border}`}>
                <div className="row">
                    <div className="col-7">
                        <div className={styles.logo}>
                            <img src={`${localStorage.getItem('apiUrl')}imagens/medium/logo_cor.svg`} alt="logo" />
                            <div className={styles.social}>
                                {resumo.configuracoes.facebook.length > 5 && <a href={resumo.configuracoes.facebook} title="Facebook" target={"_blank"} rel="noopener noreferrer"><img src={`${localStorage.getItem('apiUrl')}imagens/medium/facebook.svg`} alt="rede social" /></a>}
                                {resumo.configuracoes.instagram.length > 5 && <a href={resumo.configuracoes.instagram} title="Instagram" target={"_blank"} rel="noopener noreferrer"><img src={`${localStorage.getItem('apiUrl')}imagens/medium/instagram.svg`} alt="rede social" /></a>}
                                {resumo.configuracoes.linkedin.length > 5 && <a href={resumo.configuracoes.linkedin} title="linkedin" target={"_blank"} rel="noopener noreferrer"><img src={`${localStorage.getItem('apiUrl')}imagens/medium/linkedin.svg`} alt="rede social" /></a>}
                                {resumo.configuracoes.youtube.length > 5 && <a href={resumo.configuracoes.youtube} title="youtube" target={"_blank"} rel="noopener noreferrer"><img src={`${localStorage.getItem('apiUrl')}imagens/medium/youtube.svg`} alt="rede social" /></a>}
                            </div>
                        </div>
                    </div>
                    <div className="col-2">
                        <div className={styles.menu}>
                            <Link to="/" title="Home">Home</Link>
                            <Link to="/empresa" title="Sobre nós">Sobre nós</Link>
                            {resumo.posts && resumo.posts[0] && <Link to="/blog" title="Blog">Blog</Link>}
                            <Link to="/trabalhe-conosco" title="Trabalhe conosco">Trabalhe conosco</Link>
                            <Link to="/fale-conosco" title="Fale conosco">Fale conosco</Link>
                        </div>
                    </div>
                    <div className="col-3">
                        <h4 className="mb-4">Contato</h4>
                        <p className="p2 txt-weight-500">
                            Telefone: <span className="txt-weight-400">{resumo.configuracoes.telefone}</span>
                        </p>
                        <p className="p2 txt-weight-500">
                            WhatsApp: <span className={`${styles.link} txt-weight-400`} onClick={() => { window.open(`https://api.whatsapp.com/send?phone=55${resumo.configuracoes.whatsapp.replace(/\D/g, '')}`, '_blank', 'noopener,noreferrer'); }}>{resumo.configuracoes.whatsapp}</span>
                        </p>
                        <p className="p2 txt-weight-500">
                            E-mail: <span className={`${styles.link} txt-weight-400`} onClick={() => { window.open(`mailto:${resumo.configuracoes.email}`, '_blank', 'noopener,noreferrer'); }}>{resumo.configuracoes.email}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className={styles.creditos}>
                    <p className="p2">Intercont - {resumo.configuracoes.cnpj && resumo.configuracoes.cnpj.length > 1 && <>CNPJ: {resumo.configuracoes.cnpj} -</>} Todos os direitos Reservados © {new Date().getFullYear()}</p>
                    <a className="p2 txt-txtColor" href="https://fw2propaganda.com.br" title="Desenvolvido por Gustavo Barbosa" target={"_blank"} rel="noopener noreferrer">FW2 Propaganda</a>
                </div>
            </div>
        </div>
    )
}

export default Footer;